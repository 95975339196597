import React, { useState, useEffect, useRef } from 'react';
import { getEditorColor } from '../../util/ColorUtil';
import {
  containsOnlyNumbers,
  isItUndefined,
  isLinkAbsolute,
  modifyLinks,
} from '../../util/DataUtil';
import GoogleMapReact from 'google-map-react';
import settings from '../../settings/settings-development';
import MapStyles from './map-styles';
import { useRouter } from 'next/router';
import {
  calculateMapCenter,
  getHoveredStyle,
  groupLocations,
  getFacilitiesPosition,
  sortMarkers,
  getRadiusTresholdValue,
  findItemByText,
} from '../../util/MapsUtil';
import { Tooltip, Zoom } from '@mui/material';

const RADIUS_THRESHOLD = 10000000; // Distance threshold in meters
const Marker = ({ children }) => children;

const MetroMap = ({
  currentZoom,
  setCurrentZoom,
  maxZoom,
  latitude,
  longitude,
  metroMarkers,
  metroMap,
  blockTypeClass,
  width,
  setTargetSiteCode,
  hoveredSiteCode,
  regionColor,
  isSmallDevice,
}) => {
  const mapRef = useRef();
  // const [key, setKey] = useState(null);
  // const [radiusThreshold, setRadiusThreshold] = useState(getRadiusTresholdValue());
  const [radiusThreshold, setRadiusThreshold] = useState(
    getRadiusTresholdValue(currentZoom)
  );
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredChildIndex, setHoveredChildIndex] = useState(null);
  const router = useRouter();
  const [clickCounts, setClickCounts] = useState({});
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: currentZoom,
    gestureHandling: 'greedy',
  });
  const [currentCenter, setCurrentCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [groupedLocations, setGroupedLocations] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);
  // const regionColor = groupedLocations[0]?.[0]?.regionColor;

  const mapOptions = {
    minZoom: 10,
    // maxZoom: !isNaN(maxZoom) ? maxZoom : 18,
    maxZoom: !isNaN(maxZoom) ? maxZoom : 20,
    styles: MapStyles,
    fullscreenControl: false,
    // disableDefaultUI: globalMap ? false : true,
    // zoom: (metroMap || regionalMap) ? undefined : currentZoom,
    // navigationControl: true,
    // mapTypeControl: false,
    // scaleControl: false,
    // clickableIcons: false,
    // gestureHandling: 'greedy',
    // zoomControl: width <= 416 ? false : true
  };

  useEffect(() => {
    const hoveredItem = findItemByText(groupedLocations, hoveredSiteCode);

    if (!hoveredItem) {
      setHoveredIndex(null);
      setHoveredChildIndex(null);
      return;
    }

    const { index, childIndex } = hoveredItem;
    setHoveredIndex(index);
    setHoveredChildIndex(childIndex);
  }, [hoveredSiteCode]);

  useEffect(() => {
    setAccentColor();
  }, [hoveredIndex, hoveredChildIndex]);

  useEffect(() => {
    setGroupedLocations(groupLocations(metroMarkers, radiusThreshold));
  }, [radiusThreshold]);

  useEffect(() => {
    if (!isInitialRender) {
      setRadiusThreshold(getRadiusTresholdValue(currentZoom));
    }
    setIsInitialRender(false);
  }, [currentZoom]);

  const onMapChange = ({ zoom }) => {
    setHoveredIndex(null);
    setCurrentZoom(zoom);
  };

  const setAccentColor = () => {
    if (typeof document !== 'undefined') {
      const clusters = document.querySelectorAll('.marker-cluster');

      let mainColor;
      let alpha = 0.5;
      const alphaChild = 0.25;

      switch (regionColor) {
        case 'americas':
          mainColor = '31, 0, 255';
          break;
        case 'emea':
          mainColor = '119, 0, 236';
          break;
        case 'apac':
          mainColor = '1, 69, 79';
          break;
        default:
          mainColor = '31, 0, 255';
      }

      if (hoveredChildIndex !== null) {
        alpha = 0.5;
      } else if (hoveredIndex !== null) {
        alpha = 0.25;
      }

      clusters.forEach((cluster) => {
        cluster.style.setProperty('--main-color', mainColor);
        cluster.style.setProperty('--alpha', alpha);
        cluster.style.setProperty('--alpha-child', alphaChild);
      });
    }
  };

  const handleApiLoaded = (map, maps) => {
    map?.map.setOptions(mapOptions);
  };

  const handleMouseEnter = (index, item) => {
    setHoveredIndex(index);
    setHoveredChildIndex(null);
    setTargetSiteCode(item?.text);
  };

  const handleChildMouseEnter = (index, item) => {
    setHoveredChildIndex(index);
    setTargetSiteCode(item?.text);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setHoveredChildIndex(null);
    setTargetSiteCode(null);
    setClickCounts({});
  };

  const handleChildMouseLeave = () => {
    setHoveredChildIndex(null);
    setTargetSiteCode(null);
  };

  const handleMouseLeaveMobile = () => {
    setHoveredIndex(null);
    setHoveredChildIndex(null);
    setTargetSiteCode(null);
    setClickCounts({});
  };

  const handleClusterBackgroundClick = (cluster) => {
    let newZoom = currentZoom + 1;
    setCurrentZoom(newZoom);
    // setCurrentZoom(zoom);
    setCurrentCenter({
      lat: Number(cluster.latitude),
      lng: Number(cluster.longitude),
    });
    setHoveredIndex(null);
  };

  const handleMouseClick = (index, cluster, markers) => {
    setClickCounts((prevCounts) => {
      const count = prevCounts[index] || 0;

      if (count < 1) {
        const newCounts = {
          ...prevCounts,
          [index]: count + 1,
        };

        handleMouseEnter(index, cluster);
        return newCounts;
      } else {
        // handleClusterBackgroundClick(cluster);
        zoomToCluster(markers);
        return {};
      }
    });
  };

  const getFacilityMarkers = (groups) => {
    return groups.map((group, index) =>
      group.length > 1
        ? renderMapCluster(group, index)
        : renderFacilityMarkers(group, index)
    );
  };

  const renderMapCluster = (markers, index) => {
    const cluster = calculateMapCenter(markers);
    // const isHovered = true;
    const isHovered = hoveredIndex === index;
    const isDimmed = hoveredIndex !== null && hoveredIndex !== index;
    const isDesktop = width >= 1280;
    // const sortedMarkers = sortMarkers(markers);

    const clusterClasses = [
      'marker-cluster',
      cluster?.regionColor,
      `div-${blockTypeClass}-link`,
      isHovered ? 'is-hovered' : 'not-hovered',
      isDimmed ? 'dimmed' : '',
    ].join(' ');

    const handleMouseEnterEvent = isDesktop
      ? () => handleMouseEnter(index, cluster)
      : undefined;
    // const handleClickEvent = isDesktop ? () => handleClusterBackgroundClick(cluster) : () => handleMouseClick(index, cluster);
    const handleClickEvent = isDesktop
      ? () => zoomToCluster(markers)
      : () => handleMouseClick(index, cluster, markers);
    // const handleMouseLeaveEvent = isDesktop ? handleMouseLeave : handleMouseLeaveMobile;

    return (
      <Marker
        key={index}
        lat={Number(cluster?.latitude)}
        lng={Number(cluster?.longitude)}
      >
        <div
          className={clusterClasses}
          style={isHovered ? getHoveredStyle(markers?.length) : {}}
          onMouseEnter={handleMouseEnterEvent}
          onMouseLeave={handleMouseLeave}
          // onMouseLeave={handleMouseLeaveEvent}
          onClick={handleClickEvent}
        >
          {isHovered ? (
            renderClusterFacilities(markers)
          ) : (
            // renderClusterFacilities(markers.slice(0, 20)) :
            <>
              <p className="number">{markers.length}</p>
              <p className="text">Data Centers</p>
            </>
          )}
        </div>
      </Marker>
    );
  };

  const renderClusterFacilities = (markers) => {
    const limitedMarkers = markers.slice(0, 8);
    const remainingCount = markers.length - 8;
    // const sortedMarkers = sortMarkers(limitedMarkers);

    // const markerElements = sortedMarkers.map((item, index) => {
    const markerElements = limitedMarkers.map((item, index) => {
      // const isHovered = true;
      const isHovered = hoveredChildIndex === index;
      const isDimmed =
        hoveredChildIndex !== null && hoveredChildIndex !== index;
      const facilitiesPosition = getFacilitiesPosition(
        markers?.length,
        index + 1
      );

      const markerClasses = [
        'cluster-facilities',
        item?.regionColor,
        `div-${blockTypeClass}-link`,
        isHovered ? 'is-hovered' : 'not-hovered',
        isDimmed ? 'dimmed' : '',
      ].join(' ');

      return (
        <Marker
          key={item.id}
          lat={Number(item?.latitude)}
          lng={Number(item?.longitude)}
        >
          <div
            onMouseEnter={() => handleChildMouseEnter(index, item)}
            onMouseLeave={handleChildMouseLeave}
            className={markerClasses}
            style={facilitiesPosition}
            onClick={(e) => {
              e.stopPropagation();
              if (!isSmallDevice) {
                router.push(item.link);
              }
            }}
          >
            {renderTooltip(index, item, isHovered, 'cluster', markers?.length)}
            <div onMouseEnter={() => handleChildMouseEnter(index, item)}>
              <p>{item?.text}</p>
            </div>
          </div>
        </Marker>
      );
    });

    if (remainingCount > 0) {
      const remainingClasses = [
        'cluster-facilities',
        `div-${blockTypeClass}-link`,
      ].join(' ');

      markerElements.push(
        <Marker
          key="remaining"
          lat={Number(markers[8]?.latitude)}
          lng={Number(markers[8]?.longitude)}
        >
          <div
            className={remainingClasses}
            onClick={(e) => {
              e.stopPropagation();
              // handleClusterBackgroundClick(markers[8]);
              zoomToCluster(markers);
            }}
          >
            {`+${remainingCount}`}
          </div>
        </Marker>
      );
    }

    return markerElements;
  };

  const renderFacilityMarkers = (markers, index) => {
    if (!markers || markers.length === 0) {
      return null;
    }

    return markers.map((item) => {
      // const isHovered = true;
      const isHovered = hoveredIndex === index;
      const isDimmed = hoveredIndex !== null && hoveredIndex !== index;

      const markerClasses = [
        'facility-marker',
        item?.regionColor,
        `div-${blockTypeClass}-link`,
        isHovered ? 'is-hovered' : 'not-hovered',
        isDimmed ? 'dimmed' : '',
      ].join(' ');

      return (
        <Marker
          key={item.id}
          lat={Number(item?.latitude)}
          lng={Number(item?.longitude)}
        >
          <div
            onMouseEnter={() => handleMouseEnter(index, item)}
            onMouseLeave={handleMouseLeave}
          >
            {renderTooltip(index, item, isHovered, 'single', 0)}
            <div className={markerClasses}>{item?.text}</div>
          </div>
        </Marker>
      );
    });
  };

  const renderTooltip = (index, item, isHovered, markerType, length) => {
    return (
      <Tooltip
        leaveDelay={200}
        enterDelay={300}
        open={isHovered}
        enterTouchDelay={0}
        leaveTouchDelay={4000}
        TransitionComponent={Zoom}
        className="marker-tooltip"
        // placement="top-start"
        placement={getTooltipPlacement(index, length)}
        title={
          <div
            className={`map-tooltip-wrapper ${markerType} ${item?.regionColor}`}
          >
            <div className={'tooltip-title'}>
              {item?.metro} {item?.text}
            </div>
            <div className={'tooltip-link'}>
              <a
                href={item?.link || '#'}
                target={
                  item?.link && isLinkAbsolute(item.link) ? '_blank' : '_self'
                }
                rel={
                  item?.link && isLinkAbsolute(item.link)
                    ? 'noopener noreferrer'
                    : undefined
                }
              >
                <p>View Data Centers</p>
              </a>
              <img alt="arrow" src={'/images/learn-more-arrow.svg'} />
            </div>
          </div>
        }
        PopperProps={{
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom'],
                flipVariations: false,
              },
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
                tether: false,
                padding: 16,
              },
            },
          ],
        }}
      ></Tooltip>
    );
  };

  const zoomToCluster = (cluster) => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();

      cluster.forEach((obj) => {
        bounds.extend(
          new window.google.maps.LatLng(obj.latitude, obj.longitude)
        );
      });

      mapRef.current.map.fitBounds(bounds);
    }
  };

  const getTooltipPlacement = (index, length) => {
    const placements = [
      'top',
      'right-end',
      'right',
      'right-start',
      'bottom',
      'left',
      'left-start',
      'top-end',
    ];

    if (length >= 8) {
      return placements[index];
    }

    const lengthBasedPlacements = {
      1: ['top-start'],
      2: ['top', 'bottom'],
      3: ['top', 'bottom', 'left'],
      4: ['top', 'right-end', 'bottom', 'left'],
      5: ['top', 'right-end', 'bottom', 'left', 'top-start'],
      6: [
        'top',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
        'left-end',
      ],
      7: [
        'top',
        'right-end',
        'bottom-start',
        'bottom',
        'bottom-end',
        'left-end',
        'top-start',
      ],
    };

    const placementsForLength = lengthBasedPlacements[length] || ['top-start'];

    return placementsForLength[index];
  };

  return (
    <div className={'metro-map'}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: settings().googleMaps?.apiKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        zoom={currentZoom}
        center={currentCenter}
        onGoogleApiLoaded={(map, maps) => {
          mapRef.current = map;
          handleApiLoaded(map, maps);
        }}
        onChange={onMapChange}
        // key={key}
      >
        {metroMap && getFacilityMarkers(groupedLocations)}
      </GoogleMapReact>
    </div>
  );
};

export default MetroMap;
